<template>
  <div>
    <!-- where Nuxt is going to render everything into -->
    <slot />

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '~/components/Footer.vue';

export default {
  components: {
    Footer,
  },
  head: {
    titleTemplate: '%s - Next-level Application Consulting',
  },
};
</script>

<style lang="scss"></style>
